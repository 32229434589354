.SkippedPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 40vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  padding: 30px;
  text-align: center;
}

.SkippedPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(255, 255, 255, 0.75); */
  backdrop-filter: blur(3px);
}

.SkippedPopup h2 {
  user-select: none;
}

.icon {
  /* fill: red; */
  color: #4431a7;
  height: 60px;
  width: 60px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.buttons {
  display: flex;
  flex-flow: row;
  margin-top: 80px;
  /* justify-content: space-between; */
}
