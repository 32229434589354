@keyframes CompletionMessageAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.completionHeader {
  animation: CompletionMessageAnimation 3s 0.2s forwards
    cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
}

.captionFirstLine {
  animation: CompletionMessageAnimation 3s 1s forwards
    cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  margin: 20px 0px;
}

.captionSecondLine {
  animation: CompletionMessageAnimation 3s 1.5s forwards
    cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  max-width: 80%;
  margin: 20px 0px;
}

.downloadPopup {
  animation: CompletionMessageAnimation 3s 2.2s forwards
    cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  outline: none;
  user-select: none;
}
