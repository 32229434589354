@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"), url("./Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"), url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"), url("./Roboto-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
    url("./Montserrat-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("./Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"), url("./Montserrat-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("./Rubik-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"), url("./Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GilroySemiBold";
  src: local("GilroySemiBold"), url("./Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("./Rubik-400.ttf") format("truetype");
  font-weight: normal;
}
